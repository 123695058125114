import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startGlobalLoading: (state) => {
      state.isLoading = true;
    },
    stopGlobalLoading: (state) => {
      state.isLoading = false;
    }
  }
});

export const { startGlobalLoading, stopGlobalLoading } = loadingSlice.actions;
export const selectLoadingState = (state: { loading: LoadingState }) => state.loading;

export default loadingSlice.reducer;
