import { Account } from '../../types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CreateAccountMode = { mode: 'create' };
export type EditAccountMode = { mode: 'edit'; account: Account };
export type DeleteAccountMode = { mode: 'delete'; accountId: number };

export type AccountActionMode = CreateAccountMode | EditAccountMode | DeleteAccountMode;

export interface AccountsState {
  isLoading: boolean;
  accounts: Account[];
  actionMode: AccountActionMode | null;
  error: string | false;
}

const initialAccountState: AccountsState = {
  isLoading: false,
  accounts: [],
  actionMode: null,
  error: false
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialAccountState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },
    addAccount: (state, action: PayloadAction<Account>) => {
      state.accounts.push(action.payload);
    },
    setAccountActionMode: (state, action: PayloadAction<AccountActionMode | null>) => {
      state.actionMode = action.payload;
    },
    updateAccount: (state, action: PayloadAction<Account>) => {
      const updatedAccount = action.payload;
      state.accounts = state.accounts.map((category) =>
        category.id === updatedAccount.id ? updatedAccount : category
      );
    },
    deleteAccount(state, action: PayloadAction<number>) {
      const accountIdToDelete = action.payload;
      state.accounts = state.accounts.filter((account) => account.id !== accountIdToDelete);
    },
    resetCategoriesState: () => initialAccountState
  }
});

export const {
  setIsLoading,
  setAccountActionMode,
  setAccounts,
  addAccount,
  deleteAccount,
  updateAccount
} = accountsSlice.actions;

export const selectAccount = (state: { accounts: AccountsState }) => state.accounts;

export default accountsSlice.reducer;
