import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

export const AppLinearProgress = (props: LinearProgressProps) => {
  return <LinearProgress {...props} />;
};

export const CustomLinearLoading = (props: { isLoading: boolean }) => {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        top: 0,
        visibility: props.isLoading ? 'visible' : 'hidden'
      }}>
      <AppLinearProgress />
    </Box>
  );
};
