export const CURRENCY_LIST = [
  {
    value: 'BGN',
    label: 'Bulgarian Lev',
    symbol: 'лв'
  },
  {
    value: 'GBP',
    label: 'British Pound Sterling',
    symbol: '£'
  },
  {
    value: 'EUR',
    label: 'Euro',
    symbol: '€'
  },
  {
    value: 'USD',
    label: 'US Dollar',
    symbol: '$'
  }
];
export const DRAWER_MOBILE_SIZE = 400;
export const DATE_UI_FORMAT = 'D.MM.YYYY';
export const DATE_UI_MY_FORMAT = 'MM.YYYY';
