import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <main>
      <h1>An error occurred!</h1>
      <p>Could not find this page!</p>
    </main>
  );
};

export default ErrorPage;
