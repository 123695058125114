import React from 'react';
import Container from '@mui/material/Container';

interface AppContainerPropsType {
  children: React.ReactNode;
}

const AppContainer: React.FC<AppContainerPropsType> = (props) => {
  return <Container maxWidth="md">{props.children}</Container>;
};

export default AppContainer;
