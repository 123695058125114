import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types';
import {
  setLocalStorageAccessToken,
  setLocalStorageUser,
  getLocalStorageAccessToken,
  getLocalStorageUser
} from '../../utils/localStorage';

export interface UserState {
  accessToken: string | null;
  user: User | null;
  loading: boolean;
  error: string | false;
}

const initialUserState: UserState = {
  loading: false,
  error: false,
  accessToken: getLocalStorageAccessToken(),
  user: getLocalStorageUser()
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    signout() {
      setLocalStorageAccessToken(null);
      setLocalStorageUser(null);

      return {
        ...initialUserState,
        accessToken: null,
        user: null
      };
    },
    setError(state: UserState, action: PayloadAction<string | false>) {
      state.error = action.payload;
    },
    setUser(state: UserState, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },
    setAccessToken(state: UserState, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    }
  }
});

export const { setLoading, signout, setError, setUser, setAccessToken } = userSlice.actions;

export const selectUser = (state: { user: UserState }) => state.user;

export default userSlice.reducer;
