import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UiTheme } from '../types';
import { getLocalStorageTheme, setLocalStorageTheme } from '../utils/localStorage';

const initialTheme = getLocalStorageTheme() || 'light';
interface SettingsState {
  currentTheme: UiTheme;
  isOpen: boolean;
}

const initialState: SettingsState = {
  currentTheme: initialTheme,
  isOpen: false
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<UiTheme>) => {
      const newTheme = action.payload;
      setLocalStorageTheme(newTheme);
      state.currentTheme = action.payload;
    },
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }
  }
});

export const { setTheme, setIsOpen } = settingsSlice.actions;

export default settingsSlice.reducer;
