import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes';
import { store } from './store';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import CustomThemeProvider from './app/CustomThemeProvider';
import dayjs from './app/dayjsConfig';

const App: React.FC = () => {
  dayjs.locale('bg');

  return (
    <Provider store={store}>
      <CustomThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <SnackbarProvider />
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CustomThemeProvider>
    </Provider>
  );
};

export default App;
