import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
import { CustomLinearLoading } from '../components/CustomLoading';
import AppContainer from './AppContainer';
import { useAppSelector } from '../app/hooks';
import SettingsDrawer from '../components/SettingsDrawer';

const ProtectedLayout: React.FC = () => {
  const loading = useAppSelector((state) => state.loading);

  return (
    <>
      <CustomLinearLoading isLoading={loading.isLoading} />
      <ResponsiveAppBar />
      <SettingsDrawer />
      <AppContainer>
        <Outlet />
      </AppContainer>
    </>
  );
};

export default ProtectedLayout;
