import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Account } from '../../types';

interface HomeState {
  isLoading: boolean;
  accounts: Account[];
}

const initialState: HomeState = {
  isLoading: false,
  accounts: []
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    }
  }
});

export const { setLoading, setAccounts } = homeSlice.actions;

export default homeSlice.reducer;
