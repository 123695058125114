import React from 'react';
import { ThemeProvider } from '@mui/material';
import { responsiveLightTheme, responsiveDarkTheme } from '../theme';
import { useAppSelector } from './hooks';

const CustomThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentTheme = useAppSelector((state) => state.settings.currentTheme);

  const theme = React.useMemo(() => {
    return currentTheme === 'dark' ? responsiveDarkTheme : responsiveLightTheme;
  }, [currentTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
