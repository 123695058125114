import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { CustomLinearLoading } from './CustomLoading';
import { Stack } from '@mui/material';

interface DrawerHeaderProps {
  isLoading?: boolean;
  onClose: (e: React.MouseEvent) => void;
}

const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ isLoading = false, onClose }) => {
  return (
    <StyledDrawerHeader>
      <CustomLinearLoading isLoading={isLoading} />
      <Stack alignItems={'center'} justifyContent={'flex-start'} mx={2}>
        <IconButton onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </StyledDrawerHeader>
  );
};

export default DrawerHeader;
