import { Category } from '../../types/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CreateCategoryMode = { mode: 'create' };
export type EditCategoryMode = { mode: 'edit'; category: Category };
export type DeleteCategoryMode = { mode: 'delete'; categoryId: number };

export type CategoryActionMode = CreateCategoryMode | EditCategoryMode | DeleteCategoryMode;

export interface CategoriesState {
  isLoading: boolean;
  categories: Category[];
  actionMode: CategoryActionMode | null;
  error: string | false;
}

const initialCategoriesState: CategoriesState = {
  isLoading: false,
  categories: [],
  actionMode: null,
  error: false
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: initialCategoriesState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setCategory: (state, action: PayloadAction<Category>) => {
      state.categories.push(action.payload);
    },
    setCategoryActionMode: (state, action: PayloadAction<CategoryActionMode | null>) => {
      state.actionMode = action.payload;
    },
    updateCategory: (state, action: PayloadAction<Category>) => {
      const updatedCategory = action.payload;
      state.categories = state.categories.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      );
    },
    deleteCategory(state, action: PayloadAction<number>) {
      const categoryIdToDelete = action.payload;
      state.categories = state.categories.filter((category) => category.id !== categoryIdToDelete);
    },
    resetCategoriesState: () => initialCategoriesState
  }
});

export const {
  setIsLoading,
  setCategories,
  setCategory,
  setCategoryActionMode,
  updateCategory,
  deleteCategory,
  resetCategoriesState
} = categoriesSlice.actions;

export const selectCategories = (state: { categories: CategoriesState }) => state.categories;

export default categoriesSlice.reducer;
