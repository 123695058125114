import { RootState } from '../store';
import { useSelector } from 'react-redux';
import isTokenExpired from '../utils/isTokenExpired';

const useAuthApp = () => {
  const userState = useSelector((state: RootState) => state.user);

  const user = userState.user;
  const accessToken = userState.accessToken;
  const isExpired = isTokenExpired(accessToken);
  const isAuthenticated = user && accessToken && !isExpired;

  return {
    isAuthenticated
  };
};

export default useAuthApp;
