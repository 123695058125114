import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import DrawerHeader from './DrawerHeader';
import { modileModeMediaQuery } from '../utils/screenSize';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DRAWER_MOBILE_SIZE } from '../utils/constants';
import { setIsOpen, setTheme } from '../store/settingsSlice';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

const SettingsDrawer = () => {
  const { isOpen, currentTheme } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(modileModeMediaQuery);

  const handleCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event?.type === 'keydown' && (event as React.KeyboardEvent).key !== 'Escape') {
      return;
    }
    dispatch(setIsOpen(false));
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, newTheme: 'light' | 'dark') => {
    dispatch(setTheme(newTheme));
  };
  return (
    <>
      <SwipeableDrawer
        PaperProps={{
          sx: { width: isMobile ? '100%' : DRAWER_MOBILE_SIZE, borderRadius: '10px 0px 0px 10px' }
        }}
        anchor="right"
        open={isOpen}
        onClose={handleCloseDrawer}
        onOpen={() => true}>
        {isOpen && (
          <>
            <DrawerHeader onClose={handleCloseDrawer} />
            <Divider />
            <Box sx={{ m: 2 }}>
              <Typography variant="h6" gutterBottom>
                Settings
              </Typography>
              <Box role="presentation" onKeyDown={handleCloseDrawer}>
                <Stack>
                  <Typography variant="body1">Mode</Typography>
                  <ToggleButtonGroup
                    fullWidth
                    color="primary"
                    value={currentTheme}
                    exclusive
                    onChange={handleChange}
                    aria-label="mode">
                    <ToggleButton value="light" fullWidth>
                      <LightModeOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                      Light
                    </ToggleButton>
                    <ToggleButton value="dark" fullWidth>
                      <DarkModeOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                      Dark
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Box>
            </Box>
          </>
        )}
      </SwipeableDrawer>
    </>
  );
};

export default SettingsDrawer;
