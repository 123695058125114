import { UiTheme, User } from '../types';

const ACCESS_TOKEN_KEY = process.env.REACT_APP_API_ACCESS_TOKEN_KEY;
const LOCAL_STORAGE_USER_KEY = process.env.REACT_APP_LOCAL_STORAGE_USER_KEY;
const LOCAL_STORAGE_UITHEME_KEY = process.env.REACT_APP_LOCAL_STORAGE_UITHEME_KEY;

export const setLocalStorageAccessToken = (acces_token: string | null): void => {
  if (ACCESS_TOKEN_KEY) {
    localStorage.setItem(ACCESS_TOKEN_KEY, acces_token || '');
  } else {
    throw new Error('Not access token configured!');
  }
  return;
};

export const getLocalStorageAccessToken = (): string | null => {
  const access_token = localStorage.getItem(ACCESS_TOKEN_KEY || '');
  if (access_token) {
    return access_token;
  }
  return null;
};

export const setLocalStorageUser = (user: User | null): void => {
  if (LOCAL_STORAGE_USER_KEY) {
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
  } else {
    throw new Error('Not user local storage key is configured!');
  }
  return;
};

export const getLocalStorageUser = (): User | null => {
  const user = localStorage.getItem(LOCAL_STORAGE_USER_KEY || '');

  try {
    let userFromStorage = JSON.parse(user || 'null') as User | null;

    if (userFromStorage) {
      return userFromStorage;
    }
  } catch (error) {
    throw new Error('Error parsing user data from local storage:');
  }

  return null;
};

export const setLocalStorageTheme = (uiTheme: UiTheme): void => {
  if (!LOCAL_STORAGE_UITHEME_KEY) {
    console.warn('Theme key not configured!');
    return;
  }
  localStorage.setItem(LOCAL_STORAGE_UITHEME_KEY, uiTheme);
};

export const getLocalStorageTheme = (): UiTheme | null => {
  const uiThemeString = localStorage.getItem(LOCAL_STORAGE_UITHEME_KEY || '');
  if (uiThemeString) {
    return uiThemeString as UiTheme;
  }
  return null;
};
