import React, { lazy, Suspense } from 'react';
import ProtectedLayout from './layouts/ProtectedLayout';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useAuthApp from './hooks/useAuthApp';
import PublickLayout from './layouts/PublicLayout';
import ErrorPage from './pages/ErrorPage/ErrorPage';

const HomePageLazy = lazy(() => import('./pages/HomePage'));
const SigninPageLazy = lazy(() => import('./pages/SigninPage'));
const SignoutPageLazy = lazy(() => import('./pages/SignoutPage'));
const AccountsPageLazy = lazy(() => import('./pages/AccountsPage'));
const TransactionsPageLazy = lazy(() => import('./pages/TransactionsPage'));
const CategoriesPageLazy = lazy(() => import('./pages/CategoriesPage'));

type Props = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: Props) => {
  const { isAuthenticated } = useAuthApp();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/auth/signin" state={{ from: location }} replace />;
  }

  return children;
};

const requiredAuth = (
  <Suspense fallback={null}>
    <ProtectedRoute>
      <ProtectedLayout />
    </ProtectedRoute>
  </Suspense>
);

const router = createBrowserRouter([
  {
    id: 'root',
    element: requiredAuth,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePageLazy />
      },
      {
        path: 'account/:accountId/categories',
        element: <CategoriesPageLazy />
      },
      {
        path: 'account/:accountId/transactions',
        element: <TransactionsPageLazy />
      },
      {
        path: 'accounts',
        element: <AccountsPageLazy />
      }
    ]
  },
  {
    id: 'auth',
    path: 'auth',
    element: <PublickLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'signin',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SigninPageLazy />
          </Suspense>
        )
      },
      {
        path: 'signout',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SignoutPageLazy />
          </Suspense>
        )
      }
    ]
  }
]);

export default router;
