import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import loadingReducer from './loadingSlice';
import settingsReducer from './settingsSlice';
import homeReducer from './home/homeSlice';
import accountsReducer from './accounts/accountsSlice';
import transactionsReducer from './transactions/transactionSlice';
import categoriesReducer from './categories/categoriesSlice';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    user: userReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
    categories: categoriesReducer,
    loading: loadingReducer,
    settings: settingsReducer
  },
  devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
