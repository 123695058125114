import { Theme } from '@mui/material/styles';

/**
 * Each breakpoint (a key) matches with a fixed screen width (a value).
 *
 *    // extra-small
 *    xs: 0,
 *    // small
 *    sm: 600,
 *    // medium
 *    md: 900,
 *    // large
 *    lg: 1200,
 *    // extra-large
 *    xl: 1536,
 *
 */
export const modileModeMediaQuery = (theme: Theme): string => theme.breakpoints.between('xs', 'sm');
export const desktopModeMediaQuery = (theme: Theme): string => theme.breakpoints.up('sm');
