import { Theme, createTheme, responsiveFontSizes } from '@mui/material';

interface CustomTheme extends Theme {
  // Add custom theme options here if needed
}

const lightTheme: CustomTheme = createTheme({
  palette: {
    mode: 'light'
  }
});

// Dark theme
const darkTheme: CustomTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const responsiveLightTheme = responsiveFontSizes(lightTheme);
const responsiveDarkTheme = responsiveFontSizes(darkTheme);

export { responsiveLightTheme, responsiveDarkTheme };
