import { jwtDecode } from 'jwt-decode';
import { MyJwtPayload } from '../types';

function isTokenExpired(token: string | null): boolean {
  if (!token) {
    return true; // Treat no token as expired
  }

  try {
    const decodedToken = jwtDecode<MyJwtPayload>(token);

    if (!decodedToken.exp) {
      return true; // If there's no expiration time, treat as expired
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    return decodedToken.exp < currentTime; // Check if token is expired
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Treat the token as expired if decoding fails
  }
}

export default isTokenExpired;
